<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="700px"
      title="钱包退余额"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <div class="footer" style="margin-top:0;">
        <a-form-model :model="formData"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <!-- 可退金额 -->
          <a-row v-if="infoData.audit_status == 1">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="可退金额">
                <span class="font-bold">{{infoData.return_amount}}元</span>
                <!-- <span> （对应面额 {{infoData.balance}}元）</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 退款金额 -->
          <a-row v-if="infoData.audit_status == 2 || infoData.audit_status == 5 || infoData.audit_status == 6">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="退款金额">
                <span class="font-bold">{{infoData.return_amount}}元</span>
                <!-- <span> （对应面额 {{infoData.apply_amount}}元）</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          
          <!-- 状态 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="状态">
                <span v-if="infoData.audit_status == 1">待审核</span>
                <span v-if="infoData.audit_status == 2">审核通过，待财务退款</span>
                <span v-if="infoData.audit_status == 3">未通过审核</span>
                <span v-if="infoData.audit_status == 5">审核通过，财务退款中</span>
                <span v-if="infoData.audit_status == 6">审核通过，已完成退款</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 审核时间 -->
          <a-row v-if="infoData.audit_time">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="审核时间">
                <span class="mr-4">{{infoData.audit_time}} </span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 备注-可修改（已审核、已拒绝） -->
          <a-row v-if="infoData.audit_status == 2 || infoData.audit_status == 3">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" prop label="审核备注">
                <div v-if="isEidtRemarkShow" class="flex">
                  <a-input v-model="formData.audit_remarks" style="margin-right:30px;"></a-input>
                  <a-button @click="handlerEdit">保存</a-button>
                </div>
                <div v-if="!onlyRead && !isEidtRemarkShow">
                  <span>{{formData.audit_remarks}}</span>
                  <a-button class="p-0" type="link" @click="isEidtRemarkShow = true">修改备注</a-button>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 备注-不可修改（已退款） -->
          <a-row v-if="infoData.audit_status == 5 || infoData.audit_status == 6">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="审核备注">
                <span>{{infoData.audit_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          
          <!-- 退款流水 -->
          <div v-if="infoData.return_flow && infoData.return_flow.length" class="mb-3">
            <base-table
                  rowKey="flow_no"
                  :customHeight="300"
                  :columnsData="flowColumns"
                  :tableData="infoData.return_flow">
            </base-table>
          </div>

        </a-form-model>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="退款图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { 
  getWalletRefundDetail, 
  getWalletRefundAmount,
  updateWalletRefund,
} from "@/api/refund.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
    onlyRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      previewVisible: false,
      previewImage: "",
      // - flow_no string  流水号
      // - apply_amount  float 申请金额 单位：元
      // - return_amount float 退还金额 单位：元
      // - financial_account_name  string  财务账户名称
      // - return_status int 退还状态（1-未退还 ，2-已退还）
      // - return_time date  退还时间
      flowColumns: [
        // {
        //   title: "退款流水号",
        //   dataIndex: "flow_no",
        //   align: "center",
        // },
        // {
        //   title: "申请金额",
        //   dataIndex: "apply_amount",
        //   align: "center",
        //   width: 80,
        // },
        {
          title: "退还金额",
          dataIndex: "return_amount",
          align: "center",
          width: 80,
        },
        {
          title: "财务账户",
          dataIndex: "financial_account_name",
          align: "center",
        },
        {
          title: "退款状态",
          dataIndex: "return_status",
          align: "center",
          width: 80,
          slots: {
            customRender: "return_status",
          },
          slotsType: "format",
          slotsFunc: (val) => {
            return val == "2" ? "已退款" : "待退款"
          },
        },
        {
          title: "退款时间",
          dataIndex: "return_time",
          align: "center",
          // width: "80px",
        }
      ],
      infoData: {},
      formData: {
        id: this.id,
        // audit_status: 2,
        audit_remarks: "",
      },
      isEidtRemarkShow: false
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      getWalletRefundDetail({ id: this.id }).then((res) => {
        if (res.code === 0) {
          this.infoData = res.data
          let audit_amount = 0

          this.infoData.audit_refund_amount = audit_amount
          this.formData.audit_remarks = this.infoData.audit_remarks

          if(res.data.audit_status == 1){
            this.initWalletRefundAmount()
          }
        }
      })
    },

    initWalletRefundAmount(){
      getWalletRefundAmount({ id: this.id }).then((res) => {
        if (res.code === 0) {
          const data = Object.assign(this.infoData, res.data)
          this.initData = data
          // this.infoData.max_refund_balance = res.data.balance
          // this.infoData.max_refund_amount = res.data.return_amount
        }
      })
    },

    handlerEdit () {
      const params = {
        id: this.formData.id,
        audit_remarks: this.formData.audit_remarks,
      }
      updateWalletRefund(params).then(res => {
        if (res.code === 0) {
          this.$message.success('修改成功！')
          // this.$parent.initData()
          // this.$emit('update:show', false)
          this.isEidtRemarkShow = false
        }
      })
    },

    // handlerBigImg(e) {
    //   this.previewImage = e.image_url
    //   this.previewVisible = true
    // },
  },
}
</script>

<style lang="less" scoped>
.audit_amount_input {
  max-width: 140px !important;
  .ant-input-number {
    width: 100px;
    max-width: 120px;
  }
  .plus {
    margin: 0 5px;
  }
}
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    // object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>